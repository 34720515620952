<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <h2>Obvestila</h2>

                <div v-if="getUserType==2"> 
                    <div class="faq_texteditor_content">
                        <button class="as_link icon button" title="Dodaj obvestilo" @click="showModal({component: 'users/CreateNotice'})">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj obvestilo
                        </button>
                    </div>

                    <!-- <div  class="faq_texteditor_content"
                        v-for="notice in notices"
                        :key="notice.id">
                        {{convertDate(notice.created)}}
                        <vue-editor class="text-editor full_width" :editorToolbar="customToolbar" v-model="notice.text"></vue-editor>
                        <button class="button mr5" @click="edit(notice.text, notice.id)">Shrani</button>
                        <button class="button" @click="showModal({component: 'users/DeleteNotice', data:{id: notice.id}})">Izbriši</button>
                    </div>  -->
                </div>

                <!-- <div v-else>  -->
                    <div class="notices_text_content"
                        v-for="notice in filteredNotices"
                        :key="notice.id">
                        <div class="notice_title">
                            <div class="notice_date"><i>{{convertDate(notice.created)}}</i></div>
                            <div class="notice_stamp" v-if="notice.tab.includes('0') && getUser.access_sections == 1">Poslovno poročanje</div>
                            <div class="notice_stamp" v-if="notice.tab.includes('1') && getUser.access_work_plan == 1">Letni program dela</div>
                            <div class="notice_stamp" v-if="notice.tab.includes('2') && getUser.access_evalvation == 1">Samoevalvacija ŠP</div>
                        </div>
                        <span v-html="notice.text"></span>
                        <font-awesome-icon v-if="isNewNotice(notice.created)" :icon="['fas', 'exclamation-circle']" class="color_red icon_new_notices" />

                        <div class="notice_tools" v-if="getUserType==2">
                            <font-awesome-icon :icon="['fas', 'pencil']" class="as_link icon" @click="showModal({component: 'users/EditNotice', data:{id: notice.id}})"/>
                            <font-awesome-icon :icon="['fas', 'trash-alt']" class="as_link icon" @click="showModal({component: 'users/DeleteNotice', data:{id: notice.id}})"/>
                        </div>

                    </div>
                <!-- </div>  -->
            </div>
        </div> 
    </div>
</template>


<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import moment from 'moment';

export default {
    name: "Notices",
    components: {
        VueEditor,
    },
    data: function () {
        return {
            customToolbar: [["bold", "italic", "underline", "link"],
					[{ list: "ordered" }, { list: "bullet" }]],
        };
    },
    computed: {
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        // Get loged user data
        getUser(){
            return this.$store.getters.getUser;
        },

        notices() {
            if(this.$store.getters.notices)
                return Object.values(this.$store.getters.notices).reverse();
            else
                return [];
        },

        filteredNotices: function () {
            // for normal user filter the notices based on access, for others show all
            if(this.getUserType != 0)
                return this.notices;
            else
                return this.notices.filter((data) => {
                    if (
                            (data.tab.includes('0') && this.getUser.access_sections == 1) ||
                            (data.tab.includes('1') && this.getUser.access_work_plan == 1) ||
                            (data.tab.includes('2') && this.getUser.access_evalvation == 1) 
                    ) {
                        return true;
                    }
                    return false;
                });
        },
    },
    methods: {
        ...mapActions([
            "getNotices",
            "noticesRead",
            'showModal',
        ]),

        convertDate(date){
            return moment(date, 'YYYY-MM-DD HH:mm:ss').format('D. M. YYYY');
        },

        //check if there are new notices for user
        isNewNotice(notice_created){
                const notice_time = new Date(notice_created);
                const user_notice_checked = new Date(this.getUser.notices_checked);

                return notice_time > user_notice_checked;
        }

    },
    created() {
        this.getNotices();
    },
    beforeDestroy() {
        // Update read time when navigating away
        this.noticesRead();
    },
};
</script>


<style lang="scss" scoped>
</style>
