<template>
    <div v-if="filteredQuestions.length > 0" class="evalvation_questions_holder">
        <br>
        <div class="questions_list">
            <div class="evalvation_question_holder">    
  
                <div v-for="(question) in filteredQuestions" 
                    :key="question.title"
                    class="evalvation_question_holder question"
                >
                    <div class="question_title_row">
                        <span class="question_title">
                            <b>{{ question.title }}</b>
                        </span>
                    </div>

                    <div v-for="(answer, key) in question.answers" 
                        :key="key"
                    >
                        <br>
                        <div class="break_description">
                            <span>
                                {{question_title(answer)}}
                            </span>
                        </div>

                        <div class="questoion_textarea_holder">
                            <textarea style="resize: both;"
                                @onload="auto_grow($event);"
                                type="text" rows="4" disabled
                                :value="answer.answer"
                                name="textarea_answer">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
    
	export default {
		name: 'SectionsDetailQuestionsUnitedMeasures',
		components: {
        },
        data: function() {
			return {
			}
        },
        props: {
            position: {
                type: Number,
                required: true
            },
            deadline_not_closed: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            // Selected subsection object with questions data
			subsectionQuestions(){
                if(this.$store.getters.subsections_data 
                    && this.$store.getters.subsections_data[this.active_subsection_id]
                    && this.$store.getters.subsections_data[this.active_subsection_id].questions)
                    return this.$store.getters.subsections_data[this.active_subsection_id].questions;
                return[];
            },

            filteredQuestions: function() {
                let filtered = Object.values(this.subsectionQuestions).filter(data => {
                    if(data.position == this.position){
                        return true;
                    }
                    return false;
                });
                return filtered.sort((a, b) => (parseInt(a.order_number) > parseInt(b.order_number)) ? 1 : -1);

            },

            filteredSectionsByOrder: function () {
                let sections = Object.values(this.sections);
                return sections.sort((a, b) => (parseInt(a.order_number) > parseInt(b.order_number)) ? 1 : -1);
            },

            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },
        }, 

        methods: { 		
            ...mapActions([
                'showModal'
            ]),

            //Set height of textarea to fit content
            auto_grow(element) {
                element.target.style.height = "5px";
                if(element.target.value == "")
                    element.target.style.height = "85px";
                else
                    element.target.style.height = element.target.scrollHeight > 85 ? (element.target.scrollHeight)+"px" : "85px";

                element.target.scrollIntoView({ behavior: "smooth", block: "end" });
            },

            question_title(answer){
                if (answer.section_name == answer.level_name)
                    return answer.section_name;
                else
                    return answer.section_name +' ('+answer.level_name+')';
            }
        },

        watch:{
            // Resize all textareas
            subsectionQuestions(){
                this.$nextTick(()=>{
                    [...document.querySelectorAll('textarea')].forEach(ta => {
                        ta.dispatchEvent(new Event("onload"));
                    });
                });
            },
            active_subsection_id(){
                this.$nextTick(()=>{
                    [...document.querySelectorAll('textarea')].forEach(ta => {
                        ta.dispatchEvent(new Event("onload"));
                    });
                });
            }
        },

        mounted() {
            this.$nextTick(()=>{
                [...document.querySelectorAll('textarea')].forEach(ta => {
                    ta.dispatchEvent(new Event("onload"));
                });
            });
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
