<template>
    <div class="section_detail_level_holder" v-if="multipleLevels">

        <div v-for="level in getSectionLevels"
            class="level_item pointer" v-if="level.enabled == 1" 
            @click="changeLevel(level.level)" v-bind:class="{ active: sectionLevel == level.level }">
                {{level.name}}
                <font-awesome-icon v-if="missing_values_level(level.level)" :icon="['fas', 'exclamation-circle']" class="color_red icon_missing_value" />
                <font-awesome-icon v-if="getUserType == 2" :icon="['fas', 'pencil-alt']" class="as_link icon_bottom_right"
                    @click="showModal({component: 'sections/EditSectionPage', data:{name:level.name}})" />
        </div>

        <div class="level_add_holder" v-if="getUserType == 2 && getSectionLevels.level1.enabled == 1">
            <div class="level_add as_link" title="Dodaj stran" @click="showModal({component: 'sections/CreateSectionPage'})">+</div>
        </div>

        <br>
        <!-- Button for saving input data -->
        <div v-if="getUserType == 2  && getSectionStatistics && getSectionStatistics.submitted == '1'/*|| (getSectionStatistics && getSectionStatistics.submit_time !== '0000-00-00 00:00:00' && deadline_not_closed)*/" class="button_holder save_subsection_data">
            <div class="button large" @click="showModal({component: 'sections/SectionSaveAfterSubmitted'})">
                Shrani
            </div>
        </div>

        <!-- Button for saving input data -->
        <div v-if="getUserType == 2 && active_subsection_data && active_subsection_data.predefined == 1" class="button_holder save_subsection_data">
            <div class="button large predefined" @click="showModal({component: 'sections/CopyTableOverrideWarning'})">
                Shrani vsebino tabele za vse članice
            </div>
        </div>

    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
		name: 'SectionsDetailLevel',
		components: {

        },
        data: function() {
			return {
			}
        },
        props: {
            deadline_not_closed: {
                type: Boolean,
                default: true
            },
            missing_values: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            sectionLevel(){
				return this.$store.getters.section_level;
            },
            sectionId(){
				return this.$route.params.section_id;
            },
            getSectionStatistics(){
                return this.$store.getters.sections_statistics[this.sectionId];
            },
            getSectionLevels(){
                return this.$store.getters.subsections_data.levels;
            },
            multipleLevels(){
                let cnt = 0;
                for(let level in this.getSectionLevels){
                    if(this.getSectionLevels[level].enabled == 1)
                        cnt ++;
                    if(cnt > 1)
                        return true;
                }
                return false;
            },

            // Selected subsection data
			active_subsection_data(){
                let level = this.$store.getters.section_level;
                let subsections = this.$store.getters.subsections_data[level];
                let active_subsection = this.$store.getters.active_subsection_id;
				return subsections ? subsections[active_subsection] : {};
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
		}, 
        methods: { 		
            ...mapActions([
                'changeLevel',
                'showModal',
            ]),

            //return true if missing_values includes section elvel as key
            missing_values_level(level){
                return this.missing_values[level] ? true : false;
            },
        },
        created(){
            this.changeLevel('1');
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
