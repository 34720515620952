<template>
    <div v-if="subsectionStructure.columns" class="subsection_table_holder">

        <div style="margin: 0 0 20px 0; cursor: pointer;">
            <input 
                type="checkbox" 
                name="table_style" 
                id="table_style" 
                v-model="table_style" 
                style="cursor: pointer;"
            >
            <label for="table_style" style="cursor: pointer;">Alternativni pogled</label>
        </div>

        <!-- Table -->
        <div class="subsection_table_table" :class="{alternative: table_style}">

            <!-- Table header row -->
            <div class="row header">
                <!-- Table columns -->
                <div class="column column_header" v-for="(column, column_key) in subsectionStructure.columns" :key="column_key">

                    <span class="text">
                        {{ column.name }}
                    </span>

                    <span class="description">
                        {{ column.description }}
                    </span>

                </div>
            </div>

            <!-- Table data rows -->
            <div class="row united_measurements_row data" v-for="(row, row_key) in subsectionStructure.data" :key="row_key">    
                <!-- Table columns -->
                <div class="column united_measurements_column" v-for="(column, column_key) in subsectionStructure.columns" :key="column_key">
                    <div v-if="column.type === undefined">
                        <div>
                            <span>{{row[column_key]}}</span>
                        </div>
                    </div>

                    <div v-if="column.type == 1">
                        <textarea rows="1" :value="row[column_key]" disabled>
                        </textarea>
                    </div>

                    <div v-if="column.type == 2">
                        <input type="text" class="input_number" :value="row[column_key]" disabled>
                    </div>

                    <div v-if="column.type == 3">
                        <select :value="row[column_key]" disabled>
                            <option>/</option>
                            <option>DA</option>
                            <option>NE</option>
                        </select>
                    </div>

                    <div v-if="column.type == 4">
                        <textarea rows="4" :value="row[column_key]" disabled></textarea>
                    </div>

                    <div v-if="column.type == 5">
                        <div v-if="!row[column_key]">
                            Ni priponke
                        </div>

                        <div v-if="row[column_key] !== undefined && row[column_key] !== ''">
                            <div class="as_link icon left_margin floatLeft" title="Shrani priponko">
                                <a v-bind:href="row[column_key].file_url" target="_blank"><font-awesome-icon :icon="['fas', 'download']" />{{row[column_key].name}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="column.type == 6">
                        <div>
                            <span>{{row[column_key]}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
		name: 'SectionsDetailTableUnitedMeasures',
		components: {

        },
        data: function() {
			return {
                table_style: false,
			}
        },
        props: {
            deadline_not_closed: {
                type: Boolean,
                default: false
            }
        },
        computed: {

			// Selected subsection object with structure (table columns, input types...)
			subsectionStructure(){
                let subsectionStructure = this.$store.getters.subsections_data[this.active_subsection_id];
                return subsectionStructure ? subsectionStructure : {};
            },

            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },

            section_level(){
                let section_level = this.$store.getters.section_level;
                return section_level;
            },
		}, 
        methods: { 		
            ...mapActions([
				'hideModal',
                'showModal',
            ]),

            // Get column type text
			columnTypeText(type){

                let text = '';
                if(type == 1)
                    text = 'Besedilo';
                else if(type == 2)
                    text = 'Število';
                else if(type == 3)
                    text = 'Da / Ne';
                else if(type == 4)
                    text = 'Dolgo besedilo';
                else if(type == 5)
                    text = 'Datoteka';
                else if(type == 6)
                    text = 'Roleta';
                else
                    text = 'Drugo';

                return text;
            },
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
