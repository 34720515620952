<template>
	<div class="main">
		<transition 
				appear
				mode="out-in"
				enter-active-class="animated fadeIn"
				leave-active-class="animated fadeOut"
			>		
			<router-view></router-view>
		</transition>
	</div>
</template>


<script>

export default {
	name: 'Main'
}

</script>


<style lang="scss" scoped>	
	@import "../scss/style.scss";
</style>
